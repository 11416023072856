<template>
    <list :user-filter="true" />
</template>

<script>
import List from "./List";

export default {
    components: {List},
};
</script>
