export default {
    data() {
        return {
            buffer: {},
            fields: null,
            users: null,
            isBusy: false,
            errorServe: false, 
            sortBy: null,
            sortDesc: false,
            pagination: {
                currentPage: 1,
                perPage: 100,
                totalItems: 0,
            },
        };
    },
    watch: {
        "$route.query": {
            handler: "onQueryChange",
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.sortBy = this.$route.query.sortBy ?? null;
        this.sortDesc = this.$route.query.sortDesc === "true";

        this.pagination.currentPage = parseInt(this.$route.query.currentPage ?? 1);
        this.pagination.perPage = parseInt(this.$route.query.perPage ?? 100);

        if (this.userFilter) {
            this.jsonRpc("admin.users.fullList").then(async (response) => {
                this.users = response.map((user) => {
                    return {value: user.id, text: user.name};
                });
                this.users.unshift({value: 0, text: "Любой коллектор"});
            });
        }
    },

    methods: {
        itemsProvider(ctx) {
            this.isBusy = true;

            const query = this._getQueryParams(ctx);
            const key = JSON.stringify(query);

            this.$router.push({query}).catch(() => {});

            if(this.buffer[key]){
                const data = this.buffer[key];
                return new Promise((resolve)=>{
                    delete this.buffer[key];
                    resolve(data);
                });
            }

            return this.request(query)
                .then(res=>{
                    this.buffer[key] = res;
                    return res;
                })
                .catch((error) => {
                    console.error(error);
                    this.errorServe = true;
                })
                .finally(async () => {
                    this.isBusy = false;
                });
        },

        paginationLinkGen(page) {
            return "?" + (new URLSearchParams(this._getQueryParams({currentPage: page}))).toString();
        },
        refresh() {
            this.$refs.table.refresh();

            return false;
        },
    }
}